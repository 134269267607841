import React from 'react'

// import RefundHeaderSection from './components/RefundHeaderSection';
import RefundHeroSection from './components/RefundHeroSection';
import ProducerSection from './components/ProducerSection';

interface Props {
  // escrowCount: number;
  contractOwnerAddress: any;
  escrowBlockchain: any;
  account: any;
  actionEnabled: boolean;
  walletconnection: () => void;
  activeProducerArray: any;  
	activeAssetTypesArray: any;
  setTxStatus: (transactionStatus: string) => void;	
	txStatus: string;
}

const Refund: React.FC<Props> = ({ contractOwnerAddress,escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeAssetTypesArray,txStatus,setTxStatus}) => {

  return (
    <>
      <main className="main main--start p-home">
        {/* <RefundHeaderSection/>         */}
        <RefundHeroSection/>        
        <ProducerSection 
          contractOwnerAddress={contractOwnerAddress} 
          escrowBlockchain={escrowBlockchain} 
          account={account} 
          actionEnabled={actionEnabled} 
          walletconnection={walletconnection} 
          activeProducerArray={activeProducerArray} 
          activeAssetTypesArray={activeAssetTypesArray} 
          txStatus={txStatus} 
          setTxStatus={setTxStatus} />  
      </main>

    </>
  )
}

export default Refund
