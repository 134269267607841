import React, {ChangeEvent} from 'react'



interface Props {
  newAssetName: string;
  newAssetPrice: string;
  setNewAssetName: (newAssetName : string) => void;	
  setNewAssetPrice: (newAssetPrice: string) => void;	
  createNewAssets: () => void;	
  actionStatus: string;
}

const AddAssetSection: React.FC<Props> = ({ 
  newAssetName,
  newAssetPrice, 
  setNewAssetName, 
  setNewAssetPrice, 
  createNewAssets, 
  actionStatus}) => {



  const onNewAssetName = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewAssetName(e.target.value);
  }
  const onNewAssetPrice = (e: ChangeEvent<HTMLInputElement>): void => {
      setNewAssetPrice(e.target.value);
  }

  const createNewAssetsHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    createNewAssets();

  }

// const AddAssetSection = () => {
  return (
    
    <div className="producer__wrapper producer__wrapper-3">
    <h2 className="producer__title c-title">Asset Type</h2>
    <form className="producer__fund producer__fund--assets" action="" method="" autoComplete="off">
        <p className="producer__fund-title">Add Asset Type</p>
        <div className="producer__fund-field">
        <label>
            <p>Asset Type Name:</p>
            {/* <input type="text" name="" placeholder="Asset Type"/> */}
            <input type="text" placeholder="Asset Type" className="form-control" onChange={onNewAssetName} value={newAssetName} defaultValue="Asset Type"/>

        </label>
        </div>
        <div className="producer__fund-field">
        <label>
            <p>Asset Price(USDT):</p>
            {/* <input type="text" name="" placeholder="0"/> */}
            <input type="text" placeholder="" className="form-control" onChange={onNewAssetPrice} value={newAssetPrice} defaultValue=""/>

        </label>
        </div>
        <div className="producer__fund-field">
          <div className="c-btn__wrapper">
            {/* <a className="c-btn" href="#">Add Asset Type</a> */}
            <button type="submit" onClick={createNewAssetsHandler} className="c-btn">Add Asset Type</button>
          </div>
          <p className="text-center text-muted">{actionStatus}</p>
        </div>
    </form>
    </div>


// <StyledFundDiv0 >					
// <StyledSmallTitle className="mb-4">Add Asset Type</StyledSmallTitle>					
// <form action="">
//     <div>
//         <label className="w-100 mb-3">
//             <span className="form-label">Asset Type Name:</span>
//             <input type="text" placeholder="Asset Type" className="form-control" onChange={onNewAssetName} value={newAssetName} defaultValue="Asset Type"/>
//         </label>
//     </div>                        											
//     <div>
//         <label className="w-100 mb-3">
//             <span className="form-label">Asset Price(ETH):</span>
//             <input type="text" placeholder="" className="form-control" onChange={onNewAssetPrice} value={newAssetPrice} defaultValue=""/>
//         </label>
//     </div>
//     <hr/>
//     <div className="text-center pb-2">
//         <button type="submit" onClick={createNewAssetsHandler} className="btn btn-primary w-100">Add Asset Type</button>
//     </div>
//     <p className="text-center text-muted">{actionStatus}</p>
// </form>
// </StyledFundDiv0>
        
  )
}


export default AddAssetSection
