import React from 'react'


interface Props {
    // escrowCount: number;
    // escrowBlockchain: any;
    account: any;
    // actionEnabled: boolean;
    // walletconnection: () => void;	
    activeProducerArray: any;
    // activeProviderArray: any;
    // activeAssetTypesArray: any;
    // setTxStatus: (transactionStatus: string) => void;	
    // txStatus: string;
}
  
const FundListSection: React.FC<Props> = ({ activeProducerArray, account}) => {
  return (
    
    <div className="producer__wrapper producer__wrapper-2">
        <h2 className="producer__title c-title">List of Funds</h2>
        <div className="producer__table">
            <table className="c-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>QTY</th>
                    <th>QTY Redeemed</th>
                    <th>QTY Remaining</th>
                    <th>Amount</th>
                    <th>Redeemed</th>
                    <th>Rebate Received</th>
                    <th>Remaining</th>
                </tr>
                </thead>
                <tbody>
                    { activeProducerArray ? 
                        <>

                            {activeProducerArray.filter((producerInfo: any) => {
                                                return producerInfo['producer'] == account
                                    }).map((producerInfo:any) => (
                                        <tr>
                                            <td>{producerInfo['escrowID']}</td>
                                            <td>{producerInfo['escrowName']}</td>
                                            {/* <td scope="row">{producerInfo['producer']}</Styledtd> */}
                                            <td>{new Date(1000*parseInt(producerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
                                            <td>{producerInfo['quantity']}</td>
                                            <td>{producerInfo['redeemedQuantity']}</td>
                                            <td>{parseInt(producerInfo['quantity']) - parseInt(producerInfo['redeemedQuantity'])}</td>

                                            {/* ETH version
                                            <td>{String(parseInt(producerInfo['amount'])/1000000000000000000)} ETH</td>
                                            <td>{String(parseInt(producerInfo['redeemedAmount'])/1000000000000000000)} ETH</td>								
                                            <td>{String(parseInt(producerInfo['rebateReceivedAmount'])/1000000000000000000)} ETH</td>								
                                            <td>{String(parseInt(producerInfo['remainingAmount'])/1000000000000000000)} ETH</td> */}

                                            {/* USDT version */}
                                            <td>{String(parseInt(producerInfo['amount'])/1000000)} USDT</td>
                                            <td>{String(parseInt(producerInfo['redeemedAmount'])/1000000)} USDT</td>								
                                            <td>{String(parseInt(producerInfo['rebateReceivedAmount'])/1000000)} USDT</td>								
                                            <td>{String(parseInt(producerInfo['remainingAmount'])/1000000)} USDT</td>	
                                        </tr>
                                    ))
                            }

                            {/* {activeProducerArray.map((producerInfo:any) => (
                                <tr>
                                    <td scope="row">{producerInfo['escrowID']}</td>
                                    <td scope="row">{producerInfo['escrowName']}</td>
                                    <td scope="row">{producerInfo['producer']}</td>
                                    <td scope="row">{new Date(1000*parseInt(producerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
                                    <td scope="row">{producerInfo['quantity']}</td>
                                    <td scope="row">{String(parseInt(producerInfo['amount'])/1000000000000000000)} ETH</td>
                                    <td scope="row">{String(parseInt(producerInfo['redeemedAmount'])/1000000000000000000)} ETH</td>								
                                    <td scope="row">{String(parseInt(producerInfo['rebateReceivedAmount'])/1000000000000000000)} ETH</td>								
                                    <td scope="row">{String(parseInt(producerInfo['remainingAmount'])/1000000000000000000)} ETH</td>								
                                </tr>
                            ))} */}
                        </>
                        :
                        <>
                        </>
                    }
                {/* <tr>
                    <td>1</td>
                    <td>John Smith</td>
                    <td>2022-10-03</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>$1,429,968</td>
                    <td>$1,999</td>
                    <td>$2,999,680</td>
                    <td>$5,719,873,023</td>
                </tr> */}
                </tbody>
            </table>
        </div>
    </div>
        
  )
}


export default FundListSection
