import React from 'react'
import RedeemAssetsSection from './RedeemAssetsSection'
import RedeemStatusSection from './RedeemStatusSection'


interface Props {
  account: any;
	assetsRedeemed: string;
	totalRebateReceived: string;
	escrowAssets: string;
	actionStatus: string;
  setEscrowAssets: (escrowAssets: string) => void;	
  addNewSellerHandler: () => void;	
}
const RedeemDashboardSection: React.FC<Props> = ({ 
  assetsRedeemed, 
  totalRebateReceived,
  setEscrowAssets,
  actionStatus,
  escrowAssets,
  addNewSellerHandler,
  account
}) => {

// const RedeemDashboardSection = () => {
  return (
    <div className="producer__wrapper producer__wrapper-1">
            <h2 className="producer__title c-title">Provider Address : {account}</h2>
            <div className="producer__block-wrapper">
              <RedeemStatusSection 
                assetsRedeemed={assetsRedeemed} 
                totalRebateReceived={totalRebateReceived} />
              <RedeemAssetsSection
                setEscrowAssets={setEscrowAssets}
                escrowAssets={escrowAssets} 
                actionStatus={actionStatus}
                addNewSellerHandler={addNewSellerHandler} />
            </div>
    </div>
  )
}


export default RedeemDashboardSection
