// import React, { useState } from 'react'
// import { useHistory, useLocation, Link } from 'react-router-dom'
// import styled from 'styled-components'

const Footer = () => {
  return (

    <footer className="footer" id="footer">
      <div className="c-grid-fluid">
        <div className="footer__wrapper">
          <div>
            <nav className="footer__nav"><a href="https://re-demption.gitbook.io/re-demption/">white paper</a>
              <a href="mailto:matt@re-demption.xyz">contact</a>
            </nav>
          </div>
          <div>
            <ul className="footer__social">
              <li><a href="#"><i className="icon-font icon-twitter"></i></a></li>
              <li><a href="#"><i className="icon-font icon-discord"></i></a></li>
              <li><a href="#"><i className="icon-font icon-medium"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}


export default Footer
