import React from 'react'

const RefundHeroSection = () => {
  return (
    <section className="subheading" id="subheading">
        <div className="subheading__container subheading__container-1">
          <div className="c-grid">
            <div className="subheading__wrapper">
              <h1><span>
                  <p>01</p></span><span>
                  <p>(re)fund</p></span></h1>
            </div>
          </div>
        </div>
        <div className="subheading__container subheading__container-2">
          <div className="c-grid">
            <p className="subheading__desc">Creating and funding a producer asset escrow</p>
          </div>
        </div>
      </section>
  )
}


export default RefundHeroSection
