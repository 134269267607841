import React from 'react'

const ApplicationsSection = () => {
  return (

    <section className="producer producer--regulators" id="app">
        <div className="c-grid">
            <div className="producer__wrapper producer__wrapper-1">
                <h2 className="producer__title c-title">Applications</h2>
                <div className="producer__block-wrapper full">
                <div>
                    <div className="producer__box-wrapper three applications">
                        <a className="producer__box" href="/refund">
                            <div>
                                <h5>01</h5>
                                <p>Refund</p>
                                <h3>Posting and funding an assets in escrow</h3>
                            </div>                        
                        </a>
                        
                        <a className="producer__box" href="/redeem">
                            <div>
                                <h5>02</h5>
                                <p>Redeem</p>
                                <h3>Redeeming an assets for escrow disbursement</h3>
                            </div>                       
                        </a>

                        <a className="producer__box" href="/review">                       
                            <div>
                                <h5>03</h5>
                                <p>Review</p>
                                <h3>Reviewing state of the fund and transactions for analytics and compliance</h3>
                            </div>
                        </a>                        
                    </div>
                </div>
                </div>
                <div className="producer__media"><img src="img/img-whitepaper.png" alt=""/></div>
            </div>
        </div>
    </section>
    
  )
}


export default ApplicationsSection
