import React, { useState, useEffect } from 'react'

import FundListSection from './FundListSection'
import RedemptionsListSection from './RedemptionsListSection'
import ReviewDashboardSection from './ReviewDashboardSection'


// import { useHistory } from 'react-router-dom'
import AccountModal from "../../../components/AccountModal";
import { useDisclosure } from "@chakra-ui/react";
import ReviewStatusSection from './ReviewStatusSection'

interface Props {
  // escrowCount: number;
  // escrowBlockchain: any;
  account: any;
  // actionEnabled: boolean;
  // walletconnection: () => void;	
  activeProducerArray: any;
  activeProviderArray: any;
  // activeAssetTypesArray: any;
  // setTxStatus: (transactionStatus: string) => void;	
  txStatus: string;
}

// const Review: React.FC<Props> = ({ escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeProviderArray,activeAssetTypesArray, txStatus,setTxStatus}) => {
const RegulatorsSection: React.FC<Props> = ({ activeProducerArray, activeProviderArray,account, txStatus}) => {


  const { isOpen, onClose } = useDisclosure();  
	const [assetsFunded, setAssetsFunded] = useState('0');
  const [assetsRedeemed, setAssetsRedeemed] = useState('0');
  const [escrowOutstanding, setEscrowOutstanding] = useState('0');
  const [totalRebateReceived, setTotalRebateReceived] = useState('0');


  // const history = useHistory();


  useEffect(()=>{

		if(activeProducerArray){
			let _assetsFunded: number = 0;
			let _assetsRedeemed: number = 0;
			let _escrowOutstanding: number = 0;
			let _totalRebateReceived: number = 0;

			activeProducerArray.map((producerInfo:any) => {
				// if(producerInfo['producer'] == account){
					_assetsFunded = _assetsFunded+(parseInt(producerInfo['quantity']))
					_assetsRedeemed = _assetsRedeemed + (parseInt(producerInfo['redeemedQuantity']))

          // ETH version
					// _escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000000000000000)
					// _totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['redeemedAmount'])/1000000000000000000)

          // USDT version
					_escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000)
					_totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['redeemedAmount'])/1000000)
				// }                
			});
			
			setAssetsFunded(String(_assetsFunded.toFixed(0)));
			setAssetsRedeemed(String(_assetsRedeemed.toFixed(0)));
			setEscrowOutstanding(String(_escrowOutstanding.toFixed(1)));
			setTotalRebateReceived(String(_totalRebateReceived.toFixed(1)));
		}else {
			setAssetsFunded("0");
			setAssetsRedeemed("0");
			setEscrowOutstanding("0");
			setTotalRebateReceived("0");
		}      

    }, [activeProviderArray,account,activeProducerArray])
// const RegulatorsSection = () => {
  return (
    <section className="producer producer--regulators" id="producer">
        <div className="c-grid">
        <div className="producer__wrapper producer__wrapper-1">
            <h2 className="producer__title c-title">Reviewer Address : {account}</h2>
            <div className="producer__block-wrapper full">
              <ReviewStatusSection 
                   assetsFunded={assetsFunded}
                   assetsRedeemed={assetsRedeemed} 
                   escrowOutstanding={escrowOutstanding} 
                   totalRebateReceived={totalRebateReceived}/>
            </div>
          </div>
          {/* <ReviewDashboardSection /> */}
          <FundListSection activeProducerArray={activeProducerArray}/>
          <RedemptionsListSection activeProviderArray={activeProviderArray}/>
        </div>
        <AccountModal isOpen={isOpen} onClose={onClose}  txStatus={txStatus}/>
      </section>
  )
}


export default RegulatorsSection
