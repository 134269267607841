import React from 'react'

const HomeHeroSection = () => {
  return (
    <section className="subheading subheading--logo" id="subheading">
        <div className="subheading__container subheading__container-1">
          <div className="c-grid">
            <div className="subheading__wrapper">
              <h1><span></span><span><img src="img/sub-logo.png" alt=""/>
                  <p>proof of concept</p></span></h1>
            </div>
          </div>
        </div>
        <div className="subheading__container subheading__container-2">
          <div className="c-grid">
            <p className="subheading__desc"></p>
          </div>
        </div>
    </section>
  )
}


export default HomeHeroSection
