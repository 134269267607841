import React from 'react'

interface Props {	
	assetsRedeemed: string;
	totalRebateReceived: string;
}
const RedeemStatusSection: React.FC<Props> = ({ 
  assetsRedeemed, 
  totalRebateReceived
}) => {

  return (
    <div>
      <div className="producer__box-wrapper">
        <div className="producer__box">
          <div>
            <h5>{assetsRedeemed}</h5>
            <p>Assets Redeemed</p>
          </div>
        </div>
        <div className="producer__box">
          <div>
            <h5>{totalRebateReceived} USDT</h5>
            <p>Total Redemption Received</p>
          </div>
        </div>
      </div>
    </div>
  )
}


export default RedeemStatusSection
