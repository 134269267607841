import React from 'react'

import SubHeaderSection from './components/SubHeaderSection';
import HeadingSection from './components/HeadingSection';
import CategorySection from './components/CategorySection';
import AboutSection from './components/AboutSection';
import HomeHeroSection from './components/HomeHeroSection';
import ApplicationsSection from './components/ApplicationsSection';

interface Props {
  // escrowCount: number;
  escrowBlockchain: any;
  account: any;
  actionEnabled: boolean;
  walletconnection: () => void;	
	activeProducerArray: any;
	activeProviderArray: any;
	activeAssetTypesArray: any;
  setTxStatus: (transactionStatus: string) => void;	
	txStatus: string;
}

const AppHome: React.FC<Props> = ({ escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeProviderArray,activeAssetTypesArray, txStatus,setTxStatus}) => {
  return (
    <>
      <main className="main main--start p-home">
        {/* <SubHeaderSection/>    */}
        <HomeHeroSection />
        {/* <HeadingSection/> 
        <CategorySection/>     */}
        <ApplicationsSection />       
        {/* <AboutSection/>         */}
      </main>
    </>
  )
}

export default AppHome
