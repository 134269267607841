import React from 'react'


interface Props {
  account: any;
  activeProviderArray: any;
}

const RedemptionsListSection: React.FC<Props> = ({ activeProviderArray,account}) => {

// const RedemptionsListSection = () => {
  return (
    <div className="producer__wrapper producer__wrapper-2">
            <h2 className="producer__title c-title">List of Redemptions</h2>
			<div className="producer__table">
				<table className="c-table">
				<thead>
					<tr>
					<th>Date</th>
					<th>ID</th>
					<th>Producer</th>
					<th>QTY</th>
					<th>Redeemed Amount</th>
					</tr>
				</thead>
				<tbody>
					{activeProviderArray ?
										<>

											{activeProviderArray.filter((providerInfo: any) => {
															return providerInfo['provider'] == account
													}).map((providerInfo:any) => (
														<tr>
															<td>{new Date(1000 * parseInt(providerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
															<td>{providerInfo['providerID']}</td>
															{/* <Styledtd scope="row">{providerInfo['escrowID']}</Styledtd>
															<Styledtd scope="row">{providerInfo['escrowName']}</Styledtd> */}
															<td>{providerInfo['producer']}</td>
															{/* <Styledtd scope="row">{providerInfo['provider']}</Styledtd> */}
															<td>{providerInfo['quantity']}</td>

															{/* ETH version
															<td>{String(parseInt(providerInfo['redeemedAmount']) / 1000000000000000000)} ETH</td> */}
															
															{/* USDT version */}
															<td>{String(parseInt(providerInfo['redeemedAmount']) / 1000000)} USDT</td>
														</tr>
													))
											}
													
											{/* {activeProviderArray.map((providerInfo: any) => (
												<tr>
													<td scope="row">{providerInfo['providerID']}</td>
													<td scope="row">{providerInfo['escrowID']}</td>
													<td scope="row">{providerInfo['escrowName']}</td>
													<td scope="row">{providerInfo['producer']}</td>
													<td scope="row">{providerInfo['provider']}</td>
													<td scope="row">{new Date(1000 * parseInt(providerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
													<td scope="row">{providerInfo['quantity']}</td>
													<td scope="row">{String(parseInt(providerInfo['redeemedAmount']) / 1000000000000000000)} ETH</td>
												</tr>
											))} */}
										</>
										:
										<>
										</>
									}
					{/* <tr>
					<td>2022-10-03</td>
					<td>1</td>
					<td>John Smith</td>
					<td>-</td>
					<td>$5,719,873,023</td>
					</tr> */}
				</tbody>
				</table>
			</div>
    </div>
  )
}


export default RedemptionsListSection
