import React from 'react'



interface Props {
    assetsFunded: string;
    assetsRedeemed: string;
    escrowOutstanding: string;
    totalRebateReceived: string;
  }
  
const ReviewStatusSection: React.FC<Props> = ({ 
    assetsFunded,
    assetsRedeemed, 
    escrowOutstanding, 
    totalRebateReceived,
    
}) => {
  
  return (
    <div className="producer__block-wrapper full">
        <div className="producer__box-wrapper four">
            <div className="producer__box">
            <div>
                <h5>{assetsFunded}</h5>
                <p>Assets Funded</p>
            </div>
            </div>
            <div className="producer__box">
            <div>
                <h5>{assetsRedeemed}</h5>
                <p>Assets Redeemed</p>
            </div>
            </div>
            <div className="producer__box">
            <div>
                <h5>{escrowOutstanding} USDT</h5>
                <p>Escrow Outstanding</p>
            </div>
            </div>
            <div className="producer__box">
            <div>
                <h5>{totalRebateReceived} USDT</h5>
                <p>Total  Redemption Value Disbursed</p>
            </div>
            </div>
        </div>
    </div>
  )
}


export default ReviewStatusSection
