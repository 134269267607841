import React, { ChangeEvent, useState } from 'react'


interface Props {
	escrowAssets: string;
	actionStatus: string;
    setEscrowAssets: (escrowAssets: string) => void;
    addNewSellerHandler: () => void;	
}
const RedeemAssetsSection: React.FC<Props> = ({ 
    setEscrowAssets,
    escrowAssets, 
    actionStatus,
    addNewSellerHandler
}) => {
    const [file, setFile] = useState()


    const onUploadFileChanged = (event: any) => {
        setFile(event.target.files[0])
        console.log(event.target.files[0]);
        
        let files = event.target.files;
             console.log(files);
             let fileReader = new FileReader();
    
             fileReader.readAsText(event.target.files[0], 'UTF-8')
             fileReader.onload = e => {
                 console.log("read file : " +e.target!.result);
    
                 var assetsString = (e.target!.result! as string).replace(/ /g, '');
                 assetsString = assetsString.replace(/(\r\n|\n|\r)/gm, "");
                 assetsString = assetsString.replace(/\s/g, '');
                 console.log("adjusted asset string" + assetsString);
                 
                setEscrowAssets(assetsString);
             }
    }
    
// const RedeemAssetsSection = () => {


    const onColledtedAssets = (e: ChangeEvent<HTMLInputElement>): void => {
        setEscrowAssets(e.target.value);
    }
    const addNewSellerHandlerEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		addNewSellerHandler();
	};

  return (
    <div>
        <form className="producer__fund" action="" method="" autoComplete="off">
            <p className="producer__fund-title">Redeem Assets</p>
            <div className="producer__fund-field">
            <label>
                <p>Collected Assets</p>
                <input type="text" placeholder="1,2,3,4,5,6.7.8.9.0" className="form-control" onChange={onColledtedAssets} value={escrowAssets} />
                <p></p>
                <input type="file" id="assetsfile" name="assetsfile" onChange={onUploadFileChanged}/>
            </label>
            </div>
            <div className="producer__fund-field">
            <div className="c-btn__wrapper">
                {/* <a className="c-btn" href="#">Collect Assets</a> */}
                <button type="submit" onClick={addNewSellerHandlerEvent} className="c-btn">Collect Assets</button>
            </div>
            </div>
            <p className="text-center text-muted">{actionStatus}</p>
        </form>
    </div>
  )
}

export default RedeemAssetsSection
