import React from 'react'

interface Props {
  activeProviderArray: any;
}

const RedemptionsListSection: React.FC<Props> = ({ activeProviderArray}) => {
// const RedemptionsListSection = () => {
  return (
    <div className="producer__wrapper producer__wrapper-3">
            <h2 className="producer__title c-title">List of Redemptions(All)</h2>
            <div className="producer__table">
              <table className="c-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Producer</th>
                    <th>Provider</th>
                    <th>QTY</th>
                    <th>Redeemed Amount</th>
                  </tr>
                </thead>
                <tbody>
                  { activeProviderArray ? 
                    <>
                      {activeProviderArray.map((providerInfo:any) => (
                        <tr>
                          <td>{new Date(1000*parseInt(providerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
                          <td>{providerInfo['providerID']}</td>
                          {/* <Styledtd scope="row">{providerInfo['escrowID']}</Styledtd>
                          <Styledtd scope="row">{providerInfo['escrowName']}</Styledtd> */}
                          <td>{providerInfo['producer']}</td>
                          <td>{providerInfo['provider']}</td>
                          <td>{providerInfo['quantity']}</td>

                          {/* ETH version
                          <td>{String(parseInt(providerInfo['redeemedAmount'])/1000000000000000000)} ETH</td>								 */}

                          {/* USDT version */}
                          <td>{String(parseInt(providerInfo['redeemedAmount'])/1000000)} USDT</td>								
                        </tr>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                  {/* <tr>
                    <td>2022-10-03</td>
                    <td>1</td>
                    <td>John Smith</td>
                    <td>-</td>
                    <td>$5,719,873,023</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
    </div>
  )
}


export default RedemptionsListSection
