import React from 'react'
// import { useHistory } from 'react-router-dom'
// import styled from 'styled-components'

import { useDisclosure } from "@chakra-ui/react";
import AccountModal from "../AccountModal";
import ConnectButton from "../ConnectButton";


interface Props {
	// escrowCount: number;
	// escrowBlockchain: any;
	// account: any;
	actionEnabled: boolean;
	walletconnection: () => void;	
	// activeProducerArray: any;
	// activeProviderArray: any;	  
	// activeAssetTypesArray: any;
	setTxStatus: (transactionStatus: string) => void;	
	txStatus: string;
}

const AppHeader: React.FC<Props> = ({ actionEnabled,walletconnection, txStatus, setTxStatus}) => {
// const AppHeader: React.FC<Props> = ({ escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeProviderArray,activeAssetTypesArray, txStatus, setTxStatus}) => {

// const AppHeader = () => {

  const { isOpen, onOpen, onClose } = useDisclosure(); 

  // const history = useHistory();

  return (
    // <section className="header header--subheader">
    //   <div className="c-grid-fluid">
    //     <div className="header__wrapper">
    //       <div>
    //         <div className="logo logo--header"><a className="logo__btn" href="/"><img className="logo__img" src="img/logo.png" alt=""/>
    //             <u className="logo__divider"></u>
    //             <div className="logo__content"><span className="logo__slogan">A public utility protocol for turning trash into treasure.</span>
    //             </div></a>
    //         </div>
    //       </div>
    //       <div>
    //       <nav className="header__nav">
    //         <a href="/refund">Fund</a>
    //         <a href="/redeem">Redeem</a>
    //         <a href="/review">Review</a>
    //         <a href="#">Help</a>
    //         <ConnectButton handleOpenModal={onOpen} walletconnection={walletconnection}/> 
    //       </nav>
    //       </div>
    //     </div>
    //   </div>
    //   <AccountModal isOpen={isOpen} onClose={onClose}  txStatus={txStatus}/>
    // </section>

      <nav className="header" id="header">
        <div className="c-grid-fluid">
          <div className="header__wrapper">
            <div>
              <div className="logo logo--header">
                <a className="logo__btn" href="https://re-demption.xyz/">
                  <img className="logo__img" src="img/logo.png" alt=""/>
                  <u className="logo__divider"/>
                  <div className="logo__content">
                    <img className="logo__subimg" src="img/sub-logo.png" alt=""/>
                    <span className="logo__slogan">A public utility protocol for turning trash into treasure.</span>
                  </div>
                </a>
              </div>
              <div className="hamburger hamburger--squeeze" role="button" hamburger-js="hamburger-js">
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
            <div mobile-block-js="mobile-block-js">
              <nav className="header__nav">
                <a href="/refund">Fund</a>
                <a href="/redeem">Redeem</a>
                <a href="/review">Review</a>
                <a href="https://re-demption.gitbook.io/re-demption/">Help</a>
                {/* <a href="https://docs.re-demption.xyz/">Help</a> */}
                <div className="c-btn__wrapper">
                  <ConnectButton handleOpenModal={onOpen} walletconnection={walletconnection}/> 
                </div>
              </nav>
            </div>
          </div>
        </div>
        <AccountModal isOpen={isOpen} onClose={onClose}  txStatus={txStatus}/>

      </nav>

    
  )
}


export default AppHeader
