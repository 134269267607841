import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

type Props = {
  handleOpenModal: any;
  walletconnection: () => void;
};
const ConnectButton: React.FC<Props> = ({ handleOpenModal,  walletconnection}) => {

  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);

  function handleConnectWallet() {
    activateBrowserWallet();
    walletconnection();
  }

  return account ? (
    <>             
      <div className="c-btn__wrapper">
        <button type="button" className="c-btn" onClick={handleOpenModal}>{account &&
        `${account.slice(0, 5)}...${account.slice(
          account.length - 3,
          account.length
        )}`} : {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH</button>
        {/* <p className="mb-0 fs-6 fw-bold lh-1 text-muted">Balance: {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH</p> */}
      </div>    
    </>
  ) : (
    <div className="c-btn__wrapper">
        <button type="button" onClick={handleConnectWallet} className="c-btn">Connect Wallet</button>      
    </div>
  );
}

export default ConnectButton
