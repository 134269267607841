// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// import { AbiItem } from 'web3-utils'
// log
import fetchData from "../data/dataActions";


const connectRequest = () => {
    return {
        type: "CONNECTION_REQUEST",
    };
};

const connectSuccess = (payload: any) => {
    return {
        type: "CONNECTION_SUCCESS",
        payload,
    };
};

const connectFailed = (payload: any) => {
    return {
        type: "CONNECTION_FAILED",
        payload,
    };
};

const updateAccountRequest = (payload: any) => {
    return {
        type: "UPDATE_ACCOUNT",
        payload,
    };
};

export const connect = () => {
    return async (dispatch: any) => {
        console.log("connect start");

        dispatch(connectRequest());
        const abiResponse = await fetch("/config/abi.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        // USDT version
        const usdtabiResponse = await fetch("/config/abi_USDTG_Goerli.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const abi = await abiResponse.json();
        //USDT version
        const usdtabi = await usdtabiResponse.json();

        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        console.log("connect start", configResponse);

        const CONFIG = await configResponse.json();
        const { ethereum } = window;
        const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

        console.log("connect start metamask", metamaskIsInstalled);
        console.log("connect start metamask : Ethereum", ethereum);

        // ==================== For landing page =====================

                // For landing page
                // const web3 = new Web3(new Web3.providers.HttpProvider('https://goerli.infura.io/v3/fb97daec42234235aea95d3bc2eb7188'))
                // // const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/fb97daec42234235aea95d3bc2eb7188'))
                // console.log("connect start web3 with infura", web3);
                // // Get Static value
                // const SmartContractObj = new web3.eth.Contract(
                //     abi,
                //     CONFIG.CONTRACT_ADDRESS
                // );
                // const ActiveProducerArray = await SmartContractObj.methods.getProducerInfoArray().call();
                // const ActiveProviderArray = await SmartContractObj.methods.getProviderInfoArray().call();
                // const ActiveAssetTypesArray = await SmartContractObj.methods.getAssetsTypeArray().call();
                // const contractOwnerAddress = await SmartContractObj.methods.owner().call();

                // var producersTempArray = [];
                // for (var _i = 0; _i < ActiveProducerArray.length; _i++) {
                //     producersTempArray.push(ActiveProducerArray[_i]);
                // }
                // var providersTempArray = [];
                // for (var _j = 0; _j < ActiveProviderArray.length; _j++) {
                //     providersTempArray.push(ActiveProviderArray[_j]);
                // }
                // var assetTypesTempArray = [];
                // for (var _k = 0; _k < ActiveAssetTypesArray.length; _k++) {
                //     assetTypesTempArray.push(ActiveAssetTypesArray[_k]);
                // }

                // console.log("active asset types : ", ActiveAssetTypesArray);

                // dispatch(
                //     connectSuccess({
                //         // account: accounts[0],
                //         smartContract: SmartContractObj,
                //         // USDT
                //         // usdtsmartContract: USDTSmartContractObj,
                //         contractAddress: CONFIG.CONTRACT_ADDRESS,

                //         activeProducers: producersTempArray,
                //         activeProviders: providersTempArray,
                //         activeAssetTypes: assetTypesTempArray,
                //         contractOwnerAddress: contractOwnerAddress,
                //         web3,
                //     })
                // );
        // ================= end of For landing page ==================


        // ====================== For app ====================

            if (metamaskIsInstalled) {
                const web3 = new Web3(ethereum);

                console.log("connect start web3", web3);

                web3.setProvider(ethereum);

                try {
                    const accounts = await ethereum.request({
                        method: "eth_requestAccounts",
                    });

                    console.log("connect start accounts", accounts);

                    const networkId = await ethereum.request({
                        method: "net_version",
                    });


                    console.log("Network ID", networkId);
                    console.log("Network ID config", CONFIG.NETWORK.ID);

                    if (networkId.toString() === CONFIG.NETWORK.ID.toString()) {
                        console.log("Network ID matched");

                        const SmartContractObj = new web3.eth.Contract(
                            abi,
                            CONFIG.CONTRACT_ADDRESS
                        );

                        // USDT SmartContractObj
                        const USDTSmartContractObj = new web3.eth.Contract(
                            usdtabi,
                            CONFIG.USDT_CONTRACT_ADDRESS
                        );

                        console.log("connect start SmartContractObj", SmartContractObj);
                        console.log("connect start USDTSmartContractObj", USDTSmartContractObj);

                        const ActiveProducerArray = await SmartContractObj.methods.getProducerInfoArray().call();
                        const ActiveProviderArray = await SmartContractObj.methods.getProviderInfoArray().call();
                        const ActiveAssetTypesArray = await SmartContractObj.methods.getAssetsTypeArray().call();
                        const contractOwnerAddress = await SmartContractObj.methods.owner().call();

                        var producersTempArray = [];
                        for (var _i = 0; _i < ActiveProducerArray.length; _i++) {
                            producersTempArray.push(ActiveProducerArray[_i]);
                        }
                        var providersTempArray = [];
                        for (var _j = 0; _j < ActiveProviderArray.length; _j++) {
                            providersTempArray.push(ActiveProviderArray[_j]);
                        }
                        var assetTypesTempArray = [];
                        for (var _k = 0; _k < ActiveAssetTypesArray.length; _k++) {
                            assetTypesTempArray.push(ActiveAssetTypesArray[_k]);
                        }

                        console.log("active asset types : ", ActiveAssetTypesArray);

                        dispatch(
                            connectSuccess({
                                account: accounts[0],
                                smartContract: SmartContractObj,
                                // USDT
                                usdtsmartContract: USDTSmartContractObj,
                                contractAddress: CONFIG.CONTRACT_ADDRESS,

                                activeProducers: producersTempArray,
                                activeProviders: providersTempArray,
                                activeAssetTypes: assetTypesTempArray,
                                contractOwnerAddress: contractOwnerAddress,
                                web3,
                            })
                        );

                        console.log("connect start account, ", accounts[0]);


                        // Add listeners start
                        ethereum.on("accountsChanged", (accountsChanged: any) => {
                            console.log("connect start updatedaccounts, ", accountsChanged);
                            console.log("connect start updatedaccounts, ", accountsChanged[0]);

                            dispatch(updateAccount(accountsChanged[0]));
                        });
                        ethereum.on("chainChanged", () => {
                            window.location.reload();
                        });
                        // Add listeners end
                    } else {
                        dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
                    }
                } catch (err) {
                    dispatch(connectFailed("Something went wrong."));
                }
            } else {  
                // For App
                dispatch(connectFailed("Install Metamask."));
            }
        // ======================= end of For app ===============================



        
    };
};

export const updateAccount = (account: any) => {
    return async (dispatch: any) => {
        console.log("Update account");
        dispatch(updateAccountRequest({ account }));
        dispatch(fetchData());
    };
};
