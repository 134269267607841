import React, { useEffect, useState } from 'react'


// import { useHistory } from 'react-router-dom'
import { useDisclosure } from "@chakra-ui/react";

import AccountModal from "../../../components/AccountModal";

import RedeemDashboardSection from './RedeemDashboardSection'
import RedemptionsListSection from './RedemptionsListSection'



interface Props {
	escrowBlockchain: any;
	account: any;
	actionEnabled: boolean;
	walletconnection: () => void;
	activeProducerArray: any;
	activeProviderArray: any;	
	activeAssetTypesArray: any;
	setTxStatus: (transactionStatus: string) => void;	
	txStatus: string;
}
const ProviderSection: React.FC<Props> = ({ 
  escrowBlockchain, 
  account, 
  actionEnabled, 
  walletconnection, 
  activeProducerArray, 
  activeProviderArray,
  activeAssetTypesArray, 
  txStatus, 
  setTxStatus
}) => {



  	const { isOpen, onClose } = useDisclosure();  
	// const history = useHistory();

	// const [escrowName, setEscrowName] = useState('');
	// const [escrowID, setEscrowID] = useState('');
	const [escrowAssets, setEscrowAssets] = useState('');
	const [actionStatus, setActionStatus] = useState('');
	// const [assetsFunded, setAssetsFunded] = useState('0');
	const [assetsRedeemed, setAssetsRedeemed] = useState('0');
	// const [escrowOutstanding, setEscrowOutstanding] = useState('0');
	const [totalRebateReceived, setTotalRebateReceived] = useState('0');

  	const addNewSellerHandler = () => {

		// event.preventDefault();
		if (account) {

			if (actionEnabled) {
				var collectedAssets: Array<string> = escrowAssets.split(',').filter(asset => asset !== "").map(function(item) {
					item = item.replace(/\s/g, '');
					item = item.replace('\t','');
					return item;
				});

				console.log("Collected Assets : " + collectedAssets);


				// let isNumberArray: boolean = true;

				// for (let arrayItem of collectedAssets) {
				// 	console.log("assets", arrayItem);
				// 	if (isNaN(arrayItem)) {
				// 		isNumberArray = false;
				// 	}
				// }

				// if (isNumberArray) {
					// if (escrowID === "") {
					// 	alert("Please select Escrow ID.");
					// } else {
						if (collectedAssets) {
							addNewSeller(collectedAssets);
						} else {
							alert("Please input assets by comma separated.");
						}
					// }
				// } else {
				// 	alert("Please input valid numbers as assets");
				// }
			} else {
				alert("Please connect wallet.");
			}
		} else {
			walletconnection();
		}

	};

  	const addNewSeller = (collectedAssets: Array<string>) => {

		setActionStatus("Collecting Assets....");
		console.log("Calling addproviderUSDT");
		// USDT version
		escrowBlockchain.smartContract.methods.addProviderUSDT(collectedAssets)
		
		// ETH version
		// escrowBlockchain.smartContract.methods.addProvider(collectedAssets)
			.send({ from: account },function (err:any, res:any) {
				if (err) {
				  console.log("An error occured", err)
				  return
				}
				console.log("Hash of the transaction: " + res)
				setActionStatus("Collecting Assets.... txHash:"+res);
				setTxStatus("Collecting Assets - txHash: "+res);


				// add feature to fetch data from transaction hash
				
			  })
			.once("error", (err: any) => {
				console.log(err);
				console.log("Sorry, something went wrong please try again later.");
				alert("Sorry, something went wrong please try again later.");
				walletconnection();
				setActionStatus("");
				setTxStatus("Your transactions willl appear here...");
			})
			.then((receipt: any) => {
				console.log("Response : ", receipt);
				console.log("Transaction finished.");
				alert("Transaction finished.");
				walletconnection();
				setActionStatus("");
				setEscrowAssets('');
				setTxStatus("Your transactions willl appear here...");
		});

		// escrowBlockchain.smartContract.methods.addProvider(escrowID, collectedAssets)
			// .send({ from: account }, function (err:any, res:any) {
			// 	if (err) {
			// 	  console.log("An error occured", err)
			// 	  return
			// 	}
			// 	console.log("Hash of the transaction: " + res)
			// 	setActionStatus("Collecting Assets.... txHash:"+res);
			// 	setTxStatus("Collecting Assets - txHash: "+res);
			//   })
			// .on('error', function(error: any){ 
			// 	console.log(error);
			// 	console.log("Sorry, something went wrong please try again later.");
			// 	alert("Sorry, something went wrong please try again later.");
			// 	walletconnection();
			// 	setActionStatus("");
			// 	setTxStatus("Your transactions willl appear here...");
			// 	console.log("testing on : error : ",error) 
			// })
			// .on('transactionHash', function(transactionHash: any){ console.log("testing on : transactionHash : ",transactionHash) })
			// .on('receipt', function(receipt: any){
			//    console.log(receipt.contractAddress) // contains the new contract address
			// })
			// .on('confirmation', function(confirmationNumber: any, receipt: any){ 
			// 	console.log("testing on : confirmationNumber : ",confirmationNumber); 
			// 	console.log("testing on : receipt : ",receipt); 
			//  })
			// .then((receipt: any) => {
			// 	console.log("Response : ", receipt);
			// 	console.log("Transaction finished.");
			// 	alert("Transaction finished.");
			// 	walletconnection();
			// 	setActionStatus("");
			// 	setTxStatus("Your transactions willl appear here...");
			// });
	}

 	useEffect(() => {

		if (activeProducerArray) {
			// let _assetsFunded: number = 0;
			let _assetsRedeemed: number = 0;
			// let _escrowOutstanding: number = 0;
			let _totalRebateReceived: number = 0;

			// activeProducerArray.map((producerInfo: any) => {

			// 	if (producerInfo['producer'] == account) {
			// 		_assetsFunded = _assetsFunded + (parseInt(producerInfo['quantity']))
			// 		_assetsRedeemed = _assetsRedeemed + (parseInt(producerInfo['redeemedQuantity']))
			// 		_escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount']) / 1000000000000000000)
			// 	}
			// });

			activeProviderArray.map((providerInfo: any) => {

				if (providerInfo['provider'] == account) {
					_assetsRedeemed = _assetsRedeemed + (parseInt(providerInfo['quantity']))

					// ETH version
					// _totalRebateReceived = _totalRebateReceived + (parseInt(providerInfo['redeemedAmount']) / 1000000000000000000)

					// USDT version
					_totalRebateReceived = _totalRebateReceived + (parseInt(providerInfo['redeemedAmount']) / 1000000)
				}
			});

			// setAssetsFunded(String(_assetsFunded.toFixed(0)));
			setAssetsRedeemed(String(_assetsRedeemed.toFixed(0)));
			// setEscrowOutstanding(String(_escrowOutstanding.toFixed(5)));
			setTotalRebateReceived(String(_totalRebateReceived.toFixed(1)));
		} else {
			// setAssetsFunded("0");
			setAssetsRedeemed("0");
			// setEscrowOutstanding("0");
			setTotalRebateReceived("0");
		}

	}, [activeProviderArray, account, activeProducerArray])


// const ProviderSection = () => {
  return (
    <section className="producer producer--provider" id="producer">
        <div className="c-grid">
          <RedeemDashboardSection 
            assetsRedeemed={assetsRedeemed} 
            totalRebateReceived={totalRebateReceived} 
            setEscrowAssets={setEscrowAssets}
            escrowAssets={escrowAssets} 
            actionStatus={actionStatus} 
            addNewSellerHandler={addNewSellerHandler}
            account={account} />
          <RedemptionsListSection
            activeProviderArray={activeProviderArray}
            account={account} />
        </div>
        <AccountModal isOpen={isOpen} onClose={onClose} txStatus={txStatus}/>
    </section>
  )
}


export default ProviderSection
