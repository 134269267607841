import React from 'react'


interface Props {
  // escrowCount: number;
  // escrowBlockchain: any;
  // account: any;
  // actionEnabled: boolean;
  // walletconnection: () => void;	
  activeProducerArray: any;
  // activeProviderArray: any;
  // activeAssetTypesArray: any;
  // setTxStatus: (transactionStatus: string) => void;	
  // txStatus: string;
}

// const Review: React.FC<Props> = ({ escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeProviderArray,activeAssetTypesArray, txStatus,setTxStatus}) => {
const FundListSection: React.FC<Props> = ({ activeProducerArray}) => {


// const FundListSection = () => {
  return (
    <div className="producer__wrapper producer__wrapper-2">
            <h2 className="producer__title c-title">List of Funds(All)</h2>
            <div className="producer__table">
              <table className="c-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Date</th>
                    <th>QTY</th>
                    <th>QTY Redeemed</th>
                    <th>QTY Remaining</th>
                    <th>Amount</th>
                    <th>Redeemed</th>
                    <th>Rebate Received</th>
                    <th>Remaining</th>
                  </tr>
                </thead>
                <tbody>
                  { activeProducerArray ? 
                    <>
                      {activeProducerArray.map((producerInfo:any) => (
                        <tr>
                          <td>{producerInfo['escrowID']}</td>
                          <td>{producerInfo['escrowName']}</td>
                          <td>{producerInfo['producer']}</td>
                          <td>{new Date(1000*parseInt(producerInfo['createdDate'])).toLocaleDateString("en-US")}</td>
                          <td>{producerInfo['quantity']}</td>
                          <td>{producerInfo['redeemedQuantity']}</td>
                          <td>{parseInt(producerInfo['quantity']) - parseInt(producerInfo['redeemedQuantity'])}</td>

                          {/* ETH version
                          <td>{String(parseInt(producerInfo['amount'])/1000000000000000000)} ETH</td>
                          <td>{String(parseInt(producerInfo['redeemedAmount'])/1000000000000000000)} ETH</td>								
                          <td>{String(parseInt(producerInfo['rebateReceivedAmount'])/1000000000000000000)} ETH</td>								
                          <td>{String(parseInt(producerInfo['remainingAmount'])/1000000000000000000)} ETH</td> */}

                          {/* USDT version */}
                          <td>{String(parseInt(producerInfo['amount'])/1000000)} USDT</td>
                          <td>{String(parseInt(producerInfo['redeemedAmount'])/1000000)} USDT</td>								
                          <td>{String(parseInt(producerInfo['rebateReceivedAmount'])/1000000)} USDT</td>								
                          <td>{String(parseInt(producerInfo['remainingAmount'])/1000000)} USDT</td>								
                        </tr>
                      ))}
                    </>
                    :
                    <>
                    </>
                  }
                  {/* <tr>
                    <td>1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>                 */}
                </tbody>
              </table>
            </div>
            {/* <div className="producer__pagination-container">
              <div>
                <form>
                  <label>
                    <select>
                      <option>Show 10</option>
                      <option>Show 20</option>
                      <option>Show 30</option>
                      <option>Show 40</option>
                      <option>Show 50</option>
                    </select>
                  </label>
                </form>
              </div>
              <div>
                <div className="producer__pagination-wrapper">
                    <a href="#">
                        <i className="icon-font icon-double-arrow-left"/>
                    </a>
                    <a href="#">
                        <i className="icon-font icon-arrow-left"/>
                    </a>
                  <p>1 / <span>45</span></p><a href="#"><i className="icon-font icon-arrow-right"></i></a><a href="#"><i className="icon-font icon-double-arrow-right"></i></a>
                </div>
              </div>
            </div> */}
    </div>
  )
}


export default FundListSection
