import React, {useEffect ,useState} from 'react'

// import { useHistory } from 'react-router-dom'
import { useDisclosure } from "@chakra-ui/react";

import AccountModal from "../../../components/AccountModal";


import AddAssetSection from './AddAssetSection'
import AddFundSection from './AddFundSection'
import FundListSection from './FundListSection'
import UserFundStatusSection from './UserFundStatusSection'


interface Props {
  // escrowCount: number;
  contractOwnerAddress: any;
  escrowBlockchain: any;
  account: any;
  actionEnabled: boolean;
  walletconnection: () => void;
  activeProducerArray: any;  
	activeAssetTypesArray: any;
  setTxStatus: (transactionStatus: string) => void;	
	txStatus: string;
}

const ProducerSection: React.FC<Props> = ({ 
  contractOwnerAddress,
  escrowBlockchain, 
  account,
  actionEnabled,
  walletconnection,
  activeProducerArray,
  activeAssetTypesArray,
  txStatus,
  setTxStatus,
}) => {

// const ProducerSection = () => {

  const { isOpen, onClose } = useDisclosure();  
	// const history = useHistory();

  const [newAssetPrice, setNewAssetPrice] = useState('0');
  const [newAssetName, setNewAssetName] = useState('');
  const [escrowName, setEscrowName] = useState('');
  const [escrowAmount, setEscrowAmount] = useState('0');
  const [assetPrice, setAssetPrice] = useState('0');
  const [assetType, setAssetType] = useState('');
  const [escrowAssets, setEscrowAssets] = useState('');
  const [actionStatus, setActionStatus] = useState('');
  const [assetsFunded, setAssetsFunded] = useState('0');
  const [assetsRedeemed, setAssetsRedeemed] = useState('0');
  const [escrowOutstanding, setEscrowOutstanding] = useState('0');
  const [totalRebateReceived, setTotalRebateReceived] = useState('0');

  const createNewAssets = () => {
  // const createNewAssetsHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault();

    if(account){
        if(actionEnabled){                    
            if (newAssetName ===""){
                alert("Please input asset type name.");    
            }else{
                if (isNaN(Number(newAssetPrice))){
                    alert("Please input valid price.");
                }else{
                    if(Number(newAssetPrice) == 0){
                        alert("Asset's price can't be zero.");
                    }else{
                        console.log("new Asset type: ", newAssetName);
                        console.log("new Asset price: ", newAssetPrice);

                        // USDT version
                        let postAssetPriceWei = Number(newAssetPrice)*1000000;

                        // ETH version
                        // let postAssetPriceWei = Number(newAssetPrice)*1000000000000000000;
                        console.log("new Asset type: ", newAssetName);
                        console.log("new Asset price: ", newAssetPrice);
                        console.log("new Asset price as Wei: ", postAssetPriceWei);

                        createNewAssetType(newAssetName, String(postAssetPriceWei)); 
                    }
                   
                }
            }
        }else{
            alert("Please connect wallet.");    
        }
    }else{
        walletconnection();
    }   
  };

  const createNewAssetType = (_newAssetName: String, _postAssetPriceWei: string) => {

		setActionStatus("Adding a new Assets Type....");

		escrowBlockchain.smartContract.methods.addNewAssetType(_newAssetName,_postAssetPriceWei)
			.send({ from: account },function (err:any, res:any) {
				if (err) {
				  console.log("An error occured", err)
				  return
				}
				console.log("Hash of the transaction: " + res)
				setActionStatus("Adding a new asset.... txHash:"+res);
				setTxStatus("Adding a new asset - txHash: "+res);


				// add feature to fetch data from transaction hash
				
			  })
			.once("error", (err: any) => {
				console.log(err);
				console.log("Sorry, something went wrong please try again later.");
				alert("Sorry, something went wrong please try again later.");
				walletconnection();
				setActionStatus("");
				setTxStatus("Your transactions willl appear here...");
			})
			.then((receipt: any) => {
				console.log("Response : ", receipt);
				console.log("Transaction finished.");
				alert("Transaction finished.");
				walletconnection();
				setActionStatus("");
				setTxStatus("Your transactions willl appear here...");
		});
	}


const createNewEscrowHandler = () => {

    
    if(account){
        if(actionEnabled){

            var postedAssets: Array<string> = escrowAssets.split(',').filter(asset => asset !== "").map(function(item) {
                item = item.replace(/\s/g, '');
                item = item.replace('\t','');
                return item;
            });

            if (escrowName ===""){
                alert("Please input escrow name.");    
            }else{
                if (postedAssets.length > 0){
                    // if (postedAssets.length > 10){
                    //     alert("Maximum assets : 10 on devnet");
                    // }else{
                        // let isNumberArray: boolean = true;

                        // for (let arrayItem of postedAssets) {
                        //     console.log("assets",arrayItem);
                        //     if (isNaN(arrayItem)){
                        //         isNumberArray = false;
                        //     }
                        // }    
                        // if (isNumberArray){
                            if (isNaN(parseInt(assetType))){
                                alert("Please select Asset Type.");
                            }else{
                                let postAssetCostWei = parseInt(assetPrice)*postedAssets.length;
                                console.log("original postedAssets",postedAssets);
                                console.log("postAssetCostWei",postAssetCostWei);
                                //ETH version
                                // console.log("postAssetCostWei",parseInt(assetPrice)*postedAssets.length/1000000000000000000);
                                // USDT version
                                console.log("postAssetCostWei",parseInt(assetPrice)*postedAssets.length/1000000);
                                console.log("escrowAmount",escrowAmount);
                                console.log("escrowName",escrowName);
                                
                                // ETH version
                                // createNewEscrowETH(postedAssets, escrowName, parseInt(assetType),String(postAssetCostWei)); 

                                // USDT version
                                // createNewEscrowUSDT(postedAssets, escrowName, parseInt(assetType),String(parseInt(assetPrice)*postedAssets.length/1000000)); 
                                createNewEscrowUSDT(postedAssets, escrowName, parseInt(assetType),String(postAssetCostWei)); 
                            }
                            
                        // }else{
                        //     alert("Please input valid assets by comma separated.");
                        // }   
                    // }
                                    
                }else{
                    alert("Please input assets by comma separated.");
                }
            }
        }else{
            alert("Please connect wallet.");    
        }
    }else{
        walletconnection();
    }   
};

const createNewEscrowETH = (postedAssets : Array<string>, escrowName: string, assetTypeID: Number,postAssetsCostWei: Number) => {
  // set Static Value of 0.01 ETH
  setActionStatus("Posting Assets....");

  console.log("Assets infor before deposit",);
  console.log("postedAssets for funding",postedAssets);
  console.log("escrowName",escrowName);
  console.log("assetTypeID",assetTypeID);
  console.log("postAssetsCostWei",postAssetsCostWei);

  // ETH version
  // const resultString = escrowBlockchain.smartContract.methods.addNewProducer(postedAssets,escrowName, assetTypeID)
  
  // USDT version
  const resultString = escrowBlockchain.smartContract.methods.addNewProducerUSDT(postedAssets,escrowName, assetTypeID,postAssetsCostWei)
  // .send({value: postAssetsCostWei,
  .send({from: account},function (err:any, res:any) {
  if (err) {
    console.log("An error occured", err)
    return
  }
  console.log("Hash of the transaction: " + res)
  setActionStatus("Posting Assets.... txHash:"+res);
  setTxStatus("Posting Assets - txHash: "+res);
  })
  .once("error", (err: any) => {
    console.log(err);
    console.log("Sorry, something went wrong please try again later.");
    alert("Sorry, something went wrong please try again later.");
    walletconnection();
    setActionStatus("");
    setTxStatus("Your transactions willl appear here...");
  })
  .then((receipt: any) => {
    console.log("Response : ", receipt);
    console.log("Transaction Finished.");      
    alert("Transaction Finished.");
    walletconnection();
    setActionStatus("");

    // Initialize Values
    setEscrowAmount('0');
    setEscrowName('');
    setEscrowAssets('');
    setAssetType('');


    setTxStatus("Your transactions willl appear here...");
  });

  console.log("Result String : ", resultString);
}
const createNewEscrowUSDT = async (postedAssets : Array<string>, escrowName: string, assetTypeID: Number,postAssetsCost: string) => {

  setActionStatus("Get approval....");

  console.log("contract address", escrowBlockchain.contractAddress);
  console.log("Owner address", account);
  const AllowedValueFromUSDT = await escrowBlockchain.usdtsmartContract.methods.allowance(account,escrowBlockchain.contractAddress).call();

  console.log("Totally supplied value", AllowedValueFromUSDT);
  console.log("postAssetsCost", postAssetsCost);

  // createNewEscrowETH(postedAssets, escrowName, assetTypeID,parseInt(postAssetsCost)); 


  if(AllowedValueFromUSDT === postAssetsCost){
    // It got approved and ready to deposit payment.
    console.log("will deposit");
    // let postAssetCostWei = parseInt(postAssetsCost)*1000000;
    // let postAssetCostWeiString = String(postAssetsCost);
    createNewEscrowETH(postedAssets, escrowName, assetTypeID,parseInt(postAssetsCost)); 
  } else if (parseInt(AllowedValueFromUSDT) > parseInt(postAssetsCost))
  {
    console.log("Allowed amount is more than current cost.")
    createNewEscrowETH(postedAssets, escrowName, assetTypeID,parseInt(postAssetsCost)); 
  }
  else if (parseInt(AllowedValueFromUSDT) === 0){
    // get approval for USDT
    console.log("Need to the amount approved");
    console.log("spender address : amount",escrowBlockchain.contractAddress,postAssetsCost);
    escrowBlockchain.usdtsmartContract.methods.approve(escrowBlockchain.contractAddress,postAssetsCost)
      .send({from: account})
      .once("error", (err: any) => {
        console.log(err);
        console.log("Sorry, something went wrong please try again later.");
        alert("Sorry, something went wrong please try again later.");
      })
      .then((receipt: any) => {
        console.log(receipt);
        console.log("get approval Transaction finished.");
        // alert("Transaction finished.");
        setActionStatus("");

        createNewEscrowETH(postedAssets, escrowName, assetTypeID,parseInt(postAssetsCost)); 
      });
  }else {
    console.log("replied value: false");
    // deposit USDT
    console.log("the amount approved");      
    let postAssetCostWei = parseInt(postAssetsCost)*1000000;
    let postAssetCostWeiString = String(postAssetCostWei);
    createNewEscrowETH(postedAssets, escrowName, assetTypeID,parseInt(postAssetCostWeiString)); 


    
  }

}

  useEffect(()=>{
        
    if(activeProducerArray){
        let _assetsFunded: number = 0;
        let _assetsRedeemed: number = 0;
        let _escrowOutstanding: number = 0;
        let _totalRebateReceived: number = 0;

        activeProducerArray.map((producerInfo:any) => {
            if(producerInfo['producer'] == account){
                _assetsFunded = _assetsFunded+(parseInt(producerInfo['quantity']))
                _assetsRedeemed = _assetsRedeemed + (parseInt(producerInfo['redeemedQuantity']))

                // ETH version
                // _escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000000000000000)
                // _totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['rebateReceivedAmount'])/1000000000000000000)

                // USDT version
                _escrowOutstanding = _escrowOutstanding + (parseInt(producerInfo['remainingAmount'])/1000000)
                _totalRebateReceived = _totalRebateReceived + (parseInt(producerInfo['rebateReceivedAmount'])/1000000)
            }                
        });
        
        setAssetsFunded(String(_assetsFunded.toFixed(0)));
        setAssetsRedeemed(String(_assetsRedeemed.toFixed(0)));
        setEscrowOutstanding(String(_escrowOutstanding.toFixed(1)));
        setTotalRebateReceived(String(_totalRebateReceived.toFixed(1)));
    }else {
        setAssetsFunded("0");
        setAssetsRedeemed("0");
        setEscrowOutstanding("0");
        setTotalRebateReceived("0");
    }     
    
    // console.log("active assets array", activeAssetTypesArray);

  }, [activeProducerArray,activeAssetTypesArray,account])
  

  return (
    <section className="producer" id="producer">
        <div className="c-grid">
          <div className="producer__wrapper producer__wrapper-1">
            <h2 className="producer__title c-title">Producer Address : {account}</h2>
            <div className="producer__block-wrapper">
              <UserFundStatusSection 
                   assetsFunded={assetsFunded}
                   assetsRedeemed={assetsRedeemed} 
                   escrowOutstanding={escrowOutstanding} 
                   totalRebateReceived={totalRebateReceived}/>
              <AddFundSection 
                escrowName={escrowName}
                assetPrice={assetPrice}
                assetType={assetType}
                escrowAssets={escrowAssets} 
                escrowAmount={escrowAmount} 
                activeAssetTypesArray={activeAssetTypesArray}
                setEscrowName={setEscrowName}
                setEscrowAssets={setEscrowAssets}
                setEscrowAmount={setEscrowAmount}
                setAssetPrice={setAssetPrice}	
                setAssetType={setAssetType}	
                createNewEscrowHandler={createNewEscrowHandler} 
                actionStatus={actionStatus} />
            </div>
          </div>
          <FundListSection activeProducerArray={activeProducerArray} account={account}/>
          {/* <AddAssetSection 
            newAssetName={newAssetName} 
            newAssetPrice={newAssetPrice} 
            setNewAssetName={setNewAssetName} 
            setNewAssetPrice={setNewAssetPrice} 
            createNewAssets={createNewAssets} 
            actionStatus={actionStatus} /> */}

          {contractOwnerAddress == account ?
            <>
              <AddAssetSection 
                newAssetName={newAssetName} 
                newAssetPrice={newAssetPrice} 
                setNewAssetName={setNewAssetName} 
                setNewAssetPrice={setNewAssetPrice} 
                createNewAssets={createNewAssets} 
                actionStatus={actionStatus}  />
            </>
            :
            <>
            </>
          }
        </div>
        <AccountModal isOpen={isOpen} onClose={onClose} txStatus={txStatus}/>
      </section>
  )
}


export default ProducerSection
