import React, {ChangeEvent, useState} from 'react'
// import ReactFileReader from 'react-file-reader'




interface Props {
    // escrowCount: number;
    // escrowBlockchain: any;
    // account: any;
    // actionEnabled: boolean;
    // walletconnection: () => void;	
    // activeProducerArray: any;
    // activeProviderArray: any;
    // activeAssetTypesArray: any;
    // setTxStatus: (transactionStatus: string) => void;	
    // txStatus: string;
  
    escrowName: string;
    assetPrice: string;
    escrowAmount: string;
    escrowAssets: string;
    assetType: string;
    activeAssetTypesArray: any;
    setEscrowName: (escrowAssets: string) => void;	
    setEscrowAssets: (escrowAssets: string) => void;	
    setEscrowAmount: (setEscrowAmount: string) => void;	
    setAssetPrice: (assetPrice: string) => void;	
    setAssetType: (assetType: string) => void;	
    createNewEscrowHandler: () => void;	
    actionStatus: string;
  }
  
  const AddFundSection: React.FC<Props> = ({ 
    escrowName,
    assetPrice,
    assetType,
    escrowAssets, 
    escrowAmount, 
    activeAssetTypesArray,
    setEscrowName,
    setEscrowAssets,
    setEscrowAmount,
    setAssetPrice,	
    setAssetType,	
    createNewEscrowHandler, 
    actionStatus}) => {

const [file, setFile] = useState()


const ascii2hex = (asciistr : string) => {
    var arr = [];
    for (var i = 0, l = asciistr.length; i < l; i ++) {
        var hex = Number(asciistr.charCodeAt(i)).toString(10);
        var zerofilledhex = ('000'+hex).slice(-3);
        console.log(zerofilledhex)
        arr.push(zerofilledhex);
        // arr.push(hex);
    }
    return arr.join('');
}

const hex2ascii = (hexValue : string) => {
    var hex = hexValue.toString();//force conversion
    var str = '';
    console.log("three digit I string - original hex :  " + hex);

    for (var i = 0; i < hex.length; i += 3) {
        // var tmpvalue = 0;
        // var tmpvaluestring = hex.substring(i, 3);
        var tmpvaluestring = hex.substring(i, i+3);
        // tmpvalue = (parseInt(hex.substring(i, 3), 10));
        console.log("three digit I string :  " + tmpvaluestring);
        // console.log("three digit I number :  " + i);
        // console.log("three digit  : " + tmpvalue + "   ,   ");
        str += String.fromCharCode(parseInt(hex.substring(i, i+3), 10));
    }
    return str;
}



const createNewEscrowHandlerEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // let teststringvalue = escrowName;
    // var teststringhex = ascii2hex(teststringvalue);
    // var teststringconverted = hex2ascii(teststringhex);
    // console.log("String : "+ teststringvalue);
    // console.log("String with hex : "+ teststringhex);
    // console.log("String with converted : "+ teststringconverted);
    createNewEscrowHandler();
}
const uploadAssetsFileHandlerEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    uploadAssetsFileHandlerEventHandler();
}

const uploadAssetsFileHandlerEventHandler =(): void => {
    console.log("upload file clicked");
}

const onEscrowName = (e: ChangeEvent<HTMLInputElement>): void => {
    setEscrowName(e.target.value);
}

const onAssetType = (e: ChangeEvent<HTMLSelectElement>): void => {
    activeAssetTypesArray.map((assetType:any) => {
       if(assetType['assetTypeID'] == e.target.value){
           setAssetPrice(assetType['assetPrice']);
           console.log("Asset price :",assetPrice);
           console.log("Asset price :",assetType['assetPrice']);
           var postedAssets: Array<string> = escrowAssets.split(',').filter(asset => asset !== "").map(function(item) {
            item = item.replace(/\s/g, '');
			item = item.replace('\t','');
            return item;
            });

           // ETH version
        //    setEscrowAmount(String(parseInt(assetType['assetPrice'])*postedAssets.length/1000000000000000000));

           // USDT version
           setEscrowAmount(String(parseInt(assetType['assetPrice'])*postedAssets.length/1000000));

       }                
   });
   setAssetType(e.target.value);       
}
const onAssetPrice = (e: ChangeEvent<HTMLInputElement>): void => {
    setAssetPrice(e.target.value);
}
const onAssets = (e: ChangeEvent<HTMLInputElement>): void => {        
    setEscrowAssets(e.target.value);
    var postedAssets: Array<string> = escrowAssets.split(',').filter(asset => asset !== "").map(function(item) {
        item = item.replace(/\s/g, '');
        item = item.replace('\t','');
        return item;
    });



    // ETH version
    // setEscrowAmount(String(parseInt(assetPrice)*postedAssets.length/1000000000000000000));

    // USDT version
    setEscrowAmount(String(parseInt(assetPrice)*postedAssets.length/1000000));
    
}

const onhandleFiles = (tempFile: any) => {
    console.log(tempFile)
 
}
const onUploadFileChanged = (event: any) => {
    setFile(event.target.files[0])
    console.log(event.target.files[0]);
    
    let files = event.target.files;
         console.log(files);
         let fileReader = new FileReader();

         fileReader.readAsText(event.target.files[0], 'UTF-8')
         fileReader.onload = e => {
             console.log("read file : " +e.target!.result);

             var assetsString = (e.target!.result! as string).replace(/ /g, '');
             assetsString = assetsString.replace(/(\r\n|\n|\r|\t)/gm, "");
             console.log("adjusted asset string" + assetsString);
             
             setEscrowAssets(assetsString);
            //  console.log("escrowAssets"+ escrowAssets);

            var postedAssets: Array<string> = assetsString.split(',').map(function(item) {
                // console.log("posted asset item ", item);
                item = item.replace(/\s/g, '');
			    item = item.replace('\t','');
                return item;
            });
            var postedAssetsFiltered: Array<string> = assetsString.split(',').filter(asset => asset !== "").map(function(item) {
                // console.log("posted asset item with filtered ", item);
                item = item.replace(/\s/g, '');
			    item = item.replace('\t','');
                return item;
            });

            console.log("postedAssets"+ postedAssets.length);
            console.log("postedAssetsFiltered"+ postedAssetsFiltered.length);


            // ETH version
            // setEscrowAmount(String(parseInt(assetPrice)*postedAssets.length/1000000000000000000));

            // USDT version
            setEscrowAmount(String(parseInt(assetPrice)*postedAssetsFiltered.length/1000000));
         }
}

  
// const AddFundSection = () => {
  return (
    
    <div>
        <form className="producer__fund" action="" method="" autoComplete="off">
            <p className="producer__fund-title">Fund an Escrow</p>
            <div className="producer__fund-field">
            <label>
                <p>Name:</p>
                {/* <input type="text" name="" placeholder="Escrow Name"/> */}
                <input type="text" placeholder="Escrow Name" className="form-control" onChange={onEscrowName} value={escrowName} defaultValue="Escrow"/>

            </label>
            </div>
            <div className="producer__fund-field">
            <label>
                <p>Asset Type:</p>
                <select name="" className="form-control" onChange={onAssetType}>
                                    <option value="0" selected disabled>Select Type</option>
                                    {activeAssetTypesArray ?
                                        <>
                                            {activeAssetTypesArray.map((assetType:any) => (
                                                <option value={assetType['assetTypeID']}>{assetType['assetTypeName']}</option>																											
                                            ))}
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </select>
                {/* <select>
                <option>Select Type 1</option>
                <option>Select Type 2</option>
                <option>Select Type 3</option>
                <option>Select Type 4</option>
                <option>Select Type 5</option>
                </select> */}
            </label>
            </div>
            <div className="producer__fund-field">
            <label>
                <p>Asset Price(USDT):</p>
                {/* ETH version
                <input type="text" placeholder="" className="form-control" onChange={onAssetPrice} value={String((parseInt(assetPrice)/1000000000000000000))} defaultValue="" disabled/> */}
                {/* USDT version */}
                <input type="text" placeholder="" className="form-control" onChange={onAssetPrice} value={String((parseInt(assetPrice)/1000000))} defaultValue="" disabled/>
                {/* <input type="text" name="" placeholder="0" disabled/> */}
            </label>
            </div>
            <div className="producer__fund-field">
            <label>
                <p>Assets</p>
                {/* <input type="text" name="" placeholder="0,1,2,3,4,5,6,7,8,9,0"/> */}
                <input type="text" placeholder="1,2,3,4,5,6,7,8,9,0" className="form-control" onChange={onAssets} value={escrowAssets}/>
                <p></p>
                <input type="file" id="assetsfile" name="assetsfile" onChange={onUploadFileChanged}/>
                {/* <button type="button" onClick={uploadAssetsFileHandlerEvent} className="c-btn">Upload Assets</button> */}
                
            </label>
            </div>
            <div className="producer__fund-field">
            <p>Estimated Fund Value : {escrowAmount} USDT</p>
            </div>
            <div className="producer__fund-field">
            <div className="c-btn__wrapper">
                {/* <a className="c-btn" href="#">Post Assets</a> */}
                <button type="submit" onClick={createNewEscrowHandlerEvent} className="c-btn">Post Assets</button>
            </div>
            </div>

            <p className="text-center text-muted">{actionStatus}</p>
        </form>



        {/* <form action="">
                        <div>
							<label className="w-100 mb-3">
								<span className="form-label">Name:</span>
								<input type="text" placeholder="Escrow Name" className="form-control" onChange={onEscrowName} value={escrowName} defaultValue="Escrow"/>
							</label>
						</div>
                        <div>
                            <label className="w-100 mb-3">
                                <span className="form-label">Asset Type</span>
                                <select name="" className="form-control" onChange={onAssetType}>
                                    <option value="0" selected disabled>Select Type</option>
                                    {activeAssetTypesArray ?
                                        <>
                                            {activeAssetTypesArray.map((assetType:any) => (
                                                <option value={assetType['assetTypeID']}>{assetType['assetTypeName']}</option>																											
                                            ))}
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </select>
                            </label>
                        </div>											
						<div>
							<label className="w-100 mb-3">
								<span className="form-label">Asset Price(ETH):</span>
								<input type="text" placeholder="" className="form-control" onChange={onAssetPrice} value={String((parseInt(assetPrice)/1000000000000000000))} defaultValue="" disabled/>
							</label>
						</div>						
						<div>
							<label className="w-100 mb-3">
								<span className="form-label">Assets(limit : 10))</span>
								<input type="text" placeholder="1,2,3,4,5,6.7.8.9.0" className="form-control" onChange={onAssets} value={escrowAssets}/>
							</label>
						</div>
						<div>
							<label className="w-100 mb-3">
								<span className="form-label">Estimated Price : {escrowAmount} ETH</span>
							</label>
						</div>
                        <hr/>
						<div className="text-center pb-2">
							<button type="submit" onClick={createNewEscrowHandler} className="btn btn-primary w-100">Post Assets</button>
						</div>
						<p className="text-center text-muted">{actionStatus}</p>
        </form> */}
    </div>
        
  )
}


export default AddFundSection
