import React from 'react'

const RedeemHeroSection = () => {
  return (
    <section className="subheading" id="subheading">
        <div className="subheading__container subheading__container-1">
          <div className="c-grid">
            <div className="subheading__wrapper">
              <h1><span>
                  <p>02</p></span><span>
                  <p>(re)deem</p></span></h1>
            </div>
          </div>
        </div>
        <div className="subheading__container subheading__container-2">
          <div className="c-grid">
            <p className="subheading__desc">Submitting collected assets for redemption</p>
          </div>
        </div>
      </section>
  )
}


export default RedeemHeroSection
