import React from 'react'

// import ReviewPage from './components/ReviewPage';
import ReviewHeroSection from './components/ReviewHeroSection';
import RegulatorsSection from './components/RegulatorsSection';

interface Props {
    // escrowCount: number;
    escrowBlockchain: any;
    account: any;
    actionEnabled: boolean;
    walletconnection: () => void;	
    activeProducerArray: any;
    activeProviderArray: any;
    activeAssetTypesArray: any;
    setTxStatus: (transactionStatus: string) => void;	
    txStatus: string;
}

const Review: React.FC<Props> = ({ escrowBlockchain, account,actionEnabled,walletconnection,activeProducerArray,activeProviderArray,activeAssetTypesArray, txStatus,setTxStatus}) => {
  // const Home = () => {
  return (
    <>
      <main className="main main--start p-producer">
        {/* <ReviewPage escrowBlockchain={escrowBlockchain} account={account} actionEnabled={actionEnabled} walletconnection={walletconnection} activeProducerArray={activeProducerArray} activeProviderArray={activeProviderArray} activeAssetTypesArray={activeAssetTypesArray} txStatus={txStatus}  setTxStatus={setTxStatus}/>         */}
        <ReviewHeroSection/> 
        <RegulatorsSection activeProducerArray={activeProducerArray} activeProviderArray={activeProviderArray} account={account} txStatus={txStatus} />       
      </main>

    </>
  )
}

export default Review
